import { FC } from 'react';
import { Grid, Box, useTheme } from '@material-ui/core';

import AdvancedDental from '../../../assets/customer-logos/AdvancedDental.svg';
import Baristart from '../../../assets/customer-logos/Baristart.svg';
import DD from '../../../assets/customer-logos/DD.svg';
import ExitTheNorm from '../../../assets/customer-logos/ExitTheNorm.svg';
import GYG from '../../../assets/customer-logos/GYG.svg';
import Jigger from '../../../assets/customer-logos/Jigger.svg';
import Komma from '../../../assets/customer-logos/Komma.svg';
import MaxiCoffee from '../../../assets/customer-logos/MaxiCoffee.svg';
import Nasty from '../../../assets/customer-logos/Nasty.svg';
import Poke from '../../../assets/customer-logos/Poke.svg';
import RBC from '../../../assets/customer-logos/RBC.svg';
import ThinkerBox from '../../../assets/customer-logos/ThinkerBox.svg';

const customers = [
  RBC,
  Jigger,
  Baristart,
  ThinkerBox,
  Komma,
  Nasty,
  GYG,
  AdvancedDental,
  MaxiCoffee,
  Poke,
  ExitTheNorm,
  DD,
];

const CustomerIcons: FC = () => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {customers.map((customer) => (
        <Grid
          item
          key={customer}
          xs={6}
          sm={4}
          md={2}
          lg={2}
          style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" height="80%">
            <img src={customer} alt={customer} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomerIcons;
