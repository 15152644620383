"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var functions_1 = require("firebase/functions");
/**
 * All callable functions are here
 */
var signedUrlRepository = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-signedUrlRepository')(data);
};
var welcomeEmail = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-welcomeEmail')(data);
};
var getAllCompanyForInternalAdmin = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-internalAdmin-getAllCompanyData')();
};
var getAllUserForInternalAdmin = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-internalAdmin-getAllUserData')(data);
};
var createUser = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-internalAdmin-createUser')(data);
};
var addRead = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-addRead')(data);
};
var updateThumbUp = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-updateThumbUp')(data);
};
/**
 * For debugging callables
 */
var testCallWithAppCheckDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-testCallWithAppCheck')();
};
var testCallDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-testCall')();
};
var testWithErrorCallDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-testWithErrorCall')();
};
var testWithDataCallDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-testWithDataCall')({ message: 'hello this is calls-testWithDataCall' });
};
var analyticsCallDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-analyticsCall')({ message: 'hello this is calls-analyticsCall' });
};
var postgresCallDebug = function (firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-postgres')();
};
var payment = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-payment')(data);
};
var confirmPayment = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-confirmPayment')(data);
};
var checkEmailAndPhoneNumberExist = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-checkEmailAndPhoneNumberExist')(data);
};
var recursiveDeleteTask = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-recursiveDeleteTask')(data);
};
var updateEmail = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-updateEmail')(data);
};
var checkUnreadNotification = function (firebaseApp, data) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    return (0, functions_1.httpsCallable)(functions, 'calls-checkUnreadNotification')(data);
};
exports.default = {
    testCallWithAppCheckDebug: testCallWithAppCheckDebug,
    testCallDebug: testCallDebug,
    testWithErrorCallDebug: testWithErrorCallDebug,
    testWithDataCallDebug: testWithDataCallDebug,
    analyticsCallDebug: analyticsCallDebug,
    postgresCallDebug: postgresCallDebug,
    signedUrlRepository: signedUrlRepository,
    welcomeEmail: welcomeEmail,
    getAllCompanyForInternalAdmin: getAllCompanyForInternalAdmin,
    getAllUserForInternalAdmin: getAllUserForInternalAdmin,
    createUser: createUser,
    addRead: addRead,
    updateThumbUp: updateThumbUp,
    payment: payment,
    confirmPayment: confirmPayment,
    checkEmailAndPhoneNumberExist: checkEmailAndPhoneNumberExist,
    recursiveDeleteTask: recursiveDeleteTask,
    updateEmail: updateEmail,
    checkUnreadNotification: checkUnreadNotification,
};
