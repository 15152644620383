import * as yup from 'yup';

const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,4})[-. )]*(\d{2,4})[-. ]*(\d{2,4})(?: *x(\d+))?\s*$/;
const minimumPhone = 8;

export const registrationSchema = yup.object({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  email: yup.string().email('Email is invalid').trim().required('Email is required'),
  mobileNumber: yup
    .string()
    .matches(phoneRegExp, 'Mobile number is not valid')
    .min(minimumPhone, `It should be at least ${minimumPhone} numbers`)
    .required('Mobile number is required'),
});

export const workSpaceSchema = yup.object({
  businessName: yup.string().trim().required('Business name is required'),
  industry: yup.string().trim().required('Industry is required'),
  companySize: yup.number().min(1).max(6),
});
