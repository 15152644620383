import { FC } from 'react';
import { Box, Typography, useTheme, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import sections from '../../../shared/ultilities/sections';
import colors from '../../../shared/ultilities/colors';
import reviews from '../../../shared/ultilities/reviews';
import Carousel from '../../../shared/components/carousel/Carousel';

const Reviews: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isLgUp = !useMediaQuery(theme.breakpoints.down('md'));
  const reviewToShow = isMobile ? 1 : isSmDown ? 2 : isLgUp ? 4 : 3;

  return (
    <Box
      id={sections.reviews}
      marginTop={isMobile ? 5 : 15}
      paddingBottom={2}
      bgcolor="#F4F8FC"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={styles.headline}>Our User’s Story</Typography>
      <Carousel itemToShow={reviewToShow} offsetRation={0}>
        {reviews.map((review) => (
          <UserReview {...review} key={review.name} />
        ))}
      </Carousel>
    </Box>
  );
};

const UserReview = (props: { name: string; role: string; quote: string; image: string }) => {
  const styles = useStyles();
  return (
    <Box bgcolor="white" paddingX={3} paddingY={4} pb={7} boxShadow="3px 5px 6px #0C262A37">
      <Box display="flex" mb={4.5}>
        <img width={60} height={60} src={props.image} alt={props.name} />
        <Box ml={2}>
          <Typography className={styles.reviewerName}>{props.name}</Typography>
          <Typography className={styles.reviewerRole}>{props.role}</Typography>
        </Box>
      </Box>
      <Typography className={styles.reviewerQuote}>{props.quote}</Typography>
    </Box>
  );
};

export default Reviews;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: colors.darkBlue,
      fontSize: '1em',
      letterSpacing: 1.4,
      marginBottom: theme.spacing(1.5),
      textAlign: 'center',
    },
    headline: {
      color: colors.darkBlue,
      fontSize: '2.6rem',
      letterSpacing: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      textAlign: 'center',
    },
    reviewerName: {
      fontSize: 18,
      color: colors.darkBlue,
    },
    reviewerRole: {
      color: '#667D92',
      fontSize: 13,
    },
    reviewerQuote: {
      fontSize: 16,
      lineHeight: '150%',
      color: '#716C80',
    },
  })
);
