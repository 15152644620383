"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emulatorCustomStorage = exports.emulatorStorage = exports.emulatorFunctions = exports.emulatorFirestore = exports.emulatorAuth = void 0;
var firestore_1 = require("firebase/firestore");
var auth_1 = require("firebase/auth");
var storage_1 = require("firebase/storage");
var functions_1 = require("firebase/functions");
function emulatorAuth(firebaseApp) {
    var auth = (0, auth_1.getAuth)(firebaseApp);
    (0, auth_1.connectAuthEmulator)(auth, 'http://localhost:9099');
    return console.log('connectAuthEmulator');
}
exports.emulatorAuth = emulatorAuth;
function emulatorFirestore(firebaseApp) {
    var firestore = (0, firestore_1.getFirestore)(firebaseApp);
    (0, firestore_1.connectFirestoreEmulator)(firestore, 'localhost', 8080);
    return console.log('connectFirestoreEmulator');
}
exports.emulatorFirestore = emulatorFirestore;
function emulatorFunctions(firebaseApp) {
    var functions = (0, functions_1.getFunctions)(firebaseApp);
    (0, functions_1.connectFunctionsEmulator)(functions, 'localhost', 5001);
    return console.log('connectFunctionsEmulator');
}
exports.emulatorFunctions = emulatorFunctions;
function emulatorStorage(firebaseApp) {
    var storage = (0, storage_1.getStorage)(firebaseApp);
    (0, storage_1.connectStorageEmulator)(storage, 'localhost', 9199);
    return console.log('connectStorageEmulator');
}
exports.emulatorStorage = emulatorStorage;
function emulatorCustomStorage(firebaseApp, customBuckets) {
    for (var _i = 0, customBuckets_1 = customBuckets; _i < customBuckets_1.length; _i++) {
        var bucket = customBuckets_1[_i];
        var customStorage = (0, storage_1.getStorage)(firebaseApp, "gs://".concat(bucket));
        (0, storage_1.connectStorageEmulator)(customStorage, 'localhost', 9199);
    }
    return console.log('connectCustomStorageEmulator', customBuckets);
}
exports.emulatorCustomStorage = emulatorCustomStorage;
