const features = [
  {
    name: 'Announcements',
    videoId: 'https://res.cloudinary.com/wearedayone/video/upload/v1644490402/Feature%20videos/announce_ytb5gd.mov',
    intro:
      'Create Messages, Reminders or Minutes for your team with our easy to read MOTD feature. And don’t forget to pin messages, it  will help your team stay on track!',
  },
  {
    name: 'Knowledge',
    videoId: 'https://res.cloudinary.com/wearedayone/video/upload/v1644490271/Feature%20videos/knowledge_m2xa8a.mov',
    intro:
      'Build your company library. Create, answer & store information in DayOne and start categorizing your information for easy viewing!',
  },
  {
    name: 'Responsibility',
    videoId:
      'https://res.cloudinary.com/wearedayone/video/upload/v1644490264/Feature%20videos/responsbility_eirbxh.mov',
    intro:
      'Schedule & assign tasks easily with our Task  Management feature. Create checklists and task guides for your team. With Responsibility, everyone now knows what to do!',
  },
  {
    name: 'Repository',
    videoId: 'https://res.cloudinary.com/wearedayone/video/upload/v1644490261/Feature%20videos/repository_h1d3oy.mov',
    intro:
      'Provide access to the right members. Drop documents, files & videos to them in a Single Click. No more fumbling through stacks of paper.',
  },
];

export default features;
