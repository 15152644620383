import { Route, Routes, Navigate } from 'react-router-dom';

import { Header } from '../shared/components/header';
import Dashboard from '../screens/dashboard/Dashboard';
import Pricing from '../screens/pricing/Pricing';
import Registration from '../screens/registration/Registration';
import AlreadyRegistered from '../screens/registration/AlreadyRegistered';
import CreateWorkspace from '../screens/registration/CreateWorkspace';
import Payment from '../screens/payment/Payment';
import PaymentSuccess from '../screens/payment/PaymentSuccess';
import PaymentFail from '../screens/payment/PaymentFail';

export default function NavigationRoute() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/already-registered" element={<AlreadyRegistered />} />
        <Route path="/create-workspace" element={<CreateWorkspace />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-fail" element={<PaymentFail />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}
