import { FC, useCallback } from 'react';
import { Box, Typography, useTheme, createStyles, makeStyles, useMediaQuery, Theme, Button } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';

import background from '../../../assets/welcome/background.svg';
import colors from '../../ultilities/colors';
import AnalyticsEvents from 'shared/ultilities/events';
import analytics from 'shared/ultilities/analytics';

const Welcome: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToRegister = useCallback(() => {
    const from = location.pathname === '/pricing' ? 'Pricing' : 'Home';
    analytics.logEvent(AnalyticsEvents.registration_start, { from });
    navigate('/registration');
  }, [navigate]);
  return (
    <Box
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      marginTop={isMobile ? 5 : 15}
      // paddingY={isMobile ? theme.spacing(0.25) : theme.spacing(1)}
      minHeight={theme.spacing(30)}>
      <img src={background} width="100%" alt="Welcome" />
      <Box position="absolute" textAlign="center">
        <Typography className={styles.title}>Welcome to the future of operations</Typography>
        <Button variant="contained" size="large" className={styles.button} onClick={navigateToRegister}>
          <Typography className={styles.buttonText}>Buy Now</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Welcome;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontSize: '2em',
      color: colors.darkBlue,
    },
    button: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
      borderRadius: 20,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5.5),
      marginTop: theme.spacing(3.5),
    },
    buttonText: { textTransform: 'capitalize', fontSize: '1.1em', fontWeight: 600 },
  })
);
