import { FC, useCallback } from 'react';
import { Grid, Box, Typography, useTheme, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../shared/components/button';
import banner from '../../../assets/banner/banner.svg';
import sections from '../../../shared/ultilities/sections';
import AnalyticsEvents from 'shared/ultilities/events';
import analytics from 'shared/ultilities/analytics';

const Banner: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const getStarted = useCallback(() => {
    analytics.logEvent(AnalyticsEvents.pricing_view, { from: 'Home' });
    navigate('/pricing');
  }, [navigate]);

  const watchDemos = useCallback(() => {
    analytics.logEvent(AnalyticsEvents.home_view_demo);
    window.open('https://www.youtube.com/watch?v=rSPbjxLGBxA&ab_channel=WeAreDayOne');
  }, []);

  return (
    <Grid container className={styles.container} id={sections.banner} spacing={isMobile ? 1 : 4}>
      <Grid item xs={12} lg={5}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Typography className={styles.title} align={isMobile || isMedium ? 'center' : 'left'}>
            The App Everyone Needs
          </Typography>
          <Typography className={styles.title2} align={isMobile || isMedium ? 'center' : 'left'}>
            Optimize & Scale your business with
            <span style={{ fontVariationSettings: `'wght' 700` }}> Dayone</span>
          </Typography>
          <Typography className={styles.text} align={isMobile || isMedium ? 'center' : 'left'}>
            DayOne helps to create Functional Teams, Clear Communications and Good Workflows.
          </Typography>
          <Typography className={styles.text2} align={isMobile || isMedium ? 'center' : 'left'}>
            <span style={{ fontVariationSettings: `'wght' 700` }}>Start </span>working better.
          </Typography>
          <Box display="flex" alignItems="center" justifyContent={isMobile || isMedium ? 'center' : 'flex-start'}>
            <Button text="Get Started" onClick={getStarted} paddingHorizontal={theme.spacing(4)} />
            <Button
              text="See Product Demo"
              onClick={watchDemos}
              paddingHorizontal={theme.spacing(4)}
              marginLeft={theme.spacing(2)}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={12} lg={7} justifyContent="center" alignItems="center">
        <Box
          // paddingX={isMobile || isMedium ? theme.spacing(0.5) : theme.spacing(2)}
          marginTop={isMobile || isMedium ? theme.spacing(1) : theme.spacing(0)}
          display="flex"
          justifyContent="center"
        >
          <img src={banner} alt="banner" className={styles.image} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(7),
      maxWidth: '100vw',
    },
    image: {
      width: '80%',
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'Italic',
      fontSize: '1.5rem',
      fontWeight: 400,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    title2: {
      fontFamily: 'Inter',
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    text: {
      fontFamily: 'Inter',
      fontSize: '1.5rem',
      fontWeight: 400,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    text2: {
      fontFamily: 'Inter',
      fontSize: '1.5rem',
      fontWeight: 400,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
  })
);
