const scrollToElement = (id: string) => {
  setTimeout(() => {
    const a = document.createElement('a');
    a.href = `#${id}`;
    a.click();
    a.remove();
  }, 100);
};

export default scrollToElement;
