import * as yup from 'yup';

const creditCardRegExp = /^[0-9]*$/;
const minimumDigit = 8;

const validationSchema = yup.object({
  countryCode: yup
    .object()
    .shape({
      iso: yup.string(),
      label: yup.string(),
      code: yup.string(),
    })
    .required('Country code is required')
    .typeError('Country code is required'),
  fullName: yup.string().trim().required('Full name is required'),
  creditCardNumber: yup
    .string()
    .matches(creditCardRegExp, 'Credit card is not valid')
    .min(minimumDigit, `It should be at least ${minimumDigit} numbers`)
    .required('Credit card number is required'),
  creditCardExpireDate: yup.string().trim().required('Credit card expire date is required'),
  cvv: yup.string().trim().required('CVV is required'),
  zipCode: yup.string().trim().required('Zip code is required'),
  streetName: yup.string().trim().required('Street name is required'),
  office: yup.string().trim().required('Apt, office, suite is required'),
  city: yup.string().trim().required('City is required'),
  state: yup.string().trim().required('State is required'),
});

export default validationSchema;
