import PropTypes from 'prop-types';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';

const YoutubeEmbed = ({ embedId, autoPlay }: any) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <div className={styles.videoresponsive}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}${autoPlay ? '?autoplay=1&mute=1&vq=hd720' : '?vq=hd720'}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
};

export default YoutubeEmbed;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoresponsive: {
      paddingBottom: '56.25%',
      position: 'relative',
      height: '100%',
      width: '100%',
      '& iframe': {
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        position: 'absolute',
      },
    },
  })
);
