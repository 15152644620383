import mixpanel from 'mixpanel-browser';

import env from 'shared/ultilities/environment';

const hasMixPanel = env.MIXPANEL.id.length > 0;

const config = () => {
  if (hasMixPanel) mixpanel.init(env.MIXPANEL.id);
};

const getBaseDimensions = async () => {
  const osName = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  const platform = 'website';

  return { osName, platform };
};

const logEvent = async (name: string, params?: object) => {
  const baseDimensions = await getBaseDimensions();
  if (hasMixPanel) mixpanel.track(name, { ...baseDimensions, ...params });
};

const analytics = { config, logEvent };

export default analytics;
