import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, TextField, Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useFormik } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { callable } from '@dayone/firebase';

import { Button } from '../../shared/components/button';
import { SignUpForm } from '../../shared/components/signUpForm';
import { ProgressIndicator } from '../../shared/components/progressIndicator';
import colors from '../../shared/ultilities/colors';
import countryCode from './helpers/countryCode';
import countryToFlag from './helpers/countryToFlag';
import labelOrIso from './helpers/labelOrIso';
import firebaseApp from '../../configs/firebaseApp';

import { IRegistrationData } from '../../contexts/app.context';
import { registrationSchema } from './helpers/validationSchema';
import { AppContext } from '../../contexts/app.context';
import analytics from 'shared/ultilities/analytics';
import AnalyticsEvents from 'shared/ultilities/events';
import formatPhoneNumber from 'screens/payment/helpers/formatPhoneNumber';

const Registration: FC = () => {
  const { data, setRegistration } = useContext(AppContext);
  const navigate = useNavigate();

  const initialValues: IRegistrationData = data.registration ?? {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    countryCode: { iso: 'SG', label: 'Singapore', code: '65' },
  };

  const styles = useStyles();

  const onSubmit = useCallback(async (values: IRegistrationData) => {
    analytics.logEvent(AnalyticsEvents.registration_enter_personal_info);
    setRegistration(values);
    setSubmitting(true);
    if (firebaseApp) {
      const { email, mobileNumber, countryCode } = values;
      const res: any = await callable.checkEmailAndPhoneNumberExist(firebaseApp, {
        email,
        phoneNumber: formatPhoneNumber(mobileNumber, countryCode.iso),
      } as any);
      const url = res.data.success ? '/create-workspace' : '/already-registered';

      navigate(url);
    }

    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    values,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit,
  });

  return (
    <SignUpForm>
      <form onSubmit={handleSubmit}>
        {isSubmitting && <ProgressIndicator />}
        <Typography className={styles.text}>Your information</Typography>
        <Box marginBottom={4}>
          <Box display="flex" alignItems="center">
            <Box marginRight={2} width="60%">
              <TextField
                fullWidth
                placeholder="First name"
                variant="outlined"
                name="firstName"
                label="First name"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur('firstName')}
                error={touched.firstName && !!errors.firstName}
              />
            </Box>
            <Box width="40%">
              <TextField
                fullWidth
                placeholder="Last name"
                variant="outlined"
                name="lastName"
                label="Last name"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur('lastName')}
                error={touched.lastName && !!errors.lastName}
              />
            </Box>
          </Box>
          {((touched.firstName && errors.firstName) || (touched.lastName && errors.lastName)) && (
            <Box display="flex" alignItems="center">
              <Box marginRight={2} width="60%">
                {touched.firstName && errors.firstName && (
                  <Typography className={styles.error}>{errors.firstName}</Typography>
                )}
              </Box>
              <Box width="40%">
                {touched.lastName && errors.lastName && (
                  <Typography className={styles.error}>{errors.lastName}</Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Email address"
            variant="outlined"
            name="email"
            label="Email address"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur('email')}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />
        </Box>
        <Box marginBottom={4}>
          <Autocomplete
            autoSelect
            fullWidth
            options={countryCode}
            onChange={(_event, value) => setFieldValue('countryCode', value)}
            value={values.countryCode}
            defaultValue={values.countryCode}
            getOptionLabel={(option) => `${labelOrIso(option)} (+${option.code} )`}
            getOptionSelected={(option, value) => (value ? option.code === value.code : true)}
            renderOption={(option) => (
              <Box display="flex" alignItems="center" textOverflow="ellipsis">
                {`${countryToFlag(option.iso)} ${labelOrIso(option)} (+${option.code})`}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                error={touched.countryCode && Boolean(errors.countryCode)}
                helperText={touched.countryCode && errors.countryCode}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Mobile number"
            variant="outlined"
            name="mobileNumber"
            label="Mobile number"
            value={values.mobileNumber}
            onChange={handleChange}
            onBlur={handleBlur('mobileNumber')}
            error={touched.mobileNumber && !!errors.mobileNumber}
            helperText={touched.mobileNumber && errors.mobileNumber}
          />
        </Box>
        <Typography className={styles.termText}>
          By clicking Continue, you agree to our{' '}
          <a href="https://docs.google.com/document/d/e/2PACX-1vQ4jOP7U6Tf-r1Ag3U7PaBum7rFc7_HSYkrPdrorGsHBpSb0ijkFQDyahkUSfr3SRxIAk7-btf3QvAj/pub">
            Terms
          </a>{' '}
          and that you have read our{' '}
          <a href="https://docs.google.com/document/d/e/2PACX-1vT5YPkrqXa6vhy5sBW31es9EFZp9T-KhJ5t57D0ulaEJzcdN8CkNv1E9tk4RusosqF9aEkuiXLURoRl/pub">
            {' '}
            Data Use Policy
          </a>
          , including our Cooke Use. DayOne may send you communications; you may change your preferences by emailing{' '}
          <a href="mailto:support@weareday.one">
            <span className={styles.supportText}>support.</span>
          </a>
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="stretch" marginY={8}>
          <Button
            text="Continue"
            onClick={handleSubmit}
            backgroundColor={colors.blueMain}
            color={colors.white}
            paddingVertical={8}
            disabled={isSubmitting}
          />
        </Box>
      </form>
    </SignUpForm>
  );
};

export default Registration;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 700,
      fontSize: '0.9rem',
      marginBottom: theme.spacing(2),
    },
    termText: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    supportText: {
      color: colors.blueMain,
    },
    error: {
      color: colors.red,
      fontSize: '0.75rem',
      marginLeft: 14,
      marginTop: 3,
    },
  })
);
