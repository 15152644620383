import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
  })
);

export default function ProgressIndicator(props: { loading?: boolean }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.loading ?? true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
