import { FC, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, useTheme, useMediaQuery, createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../shared/ultilities/colors';
import fail from '../../assets/payment/fail.svg';

import { AppContext } from '../../contexts/app.context';
import { Button } from '../../shared/components/button';
import { StartText } from '../../shared/components/startText';

const PaymentFail: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();
  const { data } = useContext(AppContext);
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const backToPayment = useCallback(() => {
    navigate('/payment');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !data.payment && navigate('/create-workspace');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box paddingY={8}>
      <Grid container className={styles.container}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <StartText />
          <Box paddingX={4}>
            <Box paddingX={isXs ? 4 : 8} paddingY={8} className={styles.wrapper}>
              <Box display="flex" justifyContent="center" paddingTop={4}>
                <img src={fail} alt="success" className={styles.img} />
              </Box>

              <Box paddingY={4}>
                <Typography className={styles.text} align="center">
                  Payment was unsuccessful. Please try again.
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" paddingY={8}>
                <Box
                  width={isMd ? '80%' : '40%'}
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  marginTop={-4}
                >
                  <Button
                    text="Update payment details"
                    color={colors.white}
                    backgroundColor={colors.black}
                    onClick={backToPayment}
                    paddingVertical={8}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentFail;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      borderRadius: 4,
      boxShadow: colors.boxShadow,
      color: colors.lightBlack,
    },
    content: {
      backgroundColor: colors.lightWhite,
      borderRadius: 4,
    },
    text: {
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: '2rem',
    },
    img: {
      maxWidth: '100%',
    },
  })
);
