const sections = {
  topNav: 'topNav',
  banner: 'banner',
  customers: 'customers',
  explanation: 'explanation',
  realLifeApplication: 'realLifeApplication',
  keyFeatures: 'keyFeatures',
  getStarted: 'getStarted',
  reviews: 'reviews',
  welcome: 'welcome',
  footer: 'footer',
  pricing: 'topNav',
  faq: 'faq',
};

export default sections;
