import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Grid, Box, Typography, useTheme, useMediaQuery, createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../ultilities/colors';
import check from '../../../assets/icons/check.svg';
import features from '../../ultilities/features.json';
import { StartText } from '../startText';

interface Props {
  children: ReactNode;
  isPayment?: boolean;
}

const SignUpForm: FC<Props> = ({ children, isPayment }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const [isShowAllFeatures, setIsShowAllFeatures] = useState<boolean>(false);

  const toggleShowAllFeatures = useCallback(() => {
    setIsShowAllFeatures(!isShowAllFeatures);
  }, [isShowAllFeatures]);

  const renderedFeatures = useMemo(() => {
    if (!isShowAllFeatures) return features.slice(0, 3);
    return features;
  }, [isShowAllFeatures]);

  const Info = useCallback(
    () => (
      <Grid item sm={12} md={12} lg={6} style={{ width: '100%' }}>
        <Box paddingX={isXs ? 2 : 8} marginBottom={isMd || isXs ? 4 : 0} height="100%" className={styles.planContent}>
          <Typography className={styles.planText}>DayOne Pro Solution</Typography>
          {/* <Typography className={styles.amountText}>$99 USD</Typography> */}
          {renderedFeatures.map((item) => (
            <Box key={item} display="flex" alignItems="center" className={styles.itemText}>
              <img src={check} alt="check" className={styles.image} />
              <Typography>{item}</Typography>
            </Box>
          ))}
          <Typography className={styles.showMoreText} onClick={toggleShowAllFeatures}>
            {isShowAllFeatures ? '- Hide' : '+ Show more'} features
          </Typography>
          {/* <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX={4}
            paddingY={2}
            marginTop={8}
            className={styles.totalContainer}
          >
            <Typography className={styles.totalText}>Today's total</Typography>
            <Typography className={styles.totalText}>USD 99</Typography>
          </Box> */}
          {isPayment && (
            <Box paddingTop={4}>
              <Typography className={styles.paymentText}>
                By clicking "Agree and subscribe", you agree: You will be charged US$99. No annual commitment required
                after the first payment. Cancel anytime via Adobe Account or Customer Support. Cancelling at any point
                of time will not provide you any refund.
              </Typography>
              <Box paddingTop={4}>
                <Typography className={styles.paymentText}>
                  You also agree to the Terms of Use and Subscription.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isXs, isMd, renderedFeatures]
  );

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
        <Box display="flex" flexDirection="column" alignItems="center" marginTop={6} marginX={isXs || isMd ? 1 : 0}>
          <StartText />
          <Box
            alignSelf="stretch"
            // display="flex"
            className={styles.wrapper}
            paddingY={8}
            marginBottom={16}>
            <Grid container>
              {isMd && <Info />}
              <Grid item sm={12} md={12} lg={6}>
                <Box paddingX={isXs ? 2 : 8}>{children}</Box>
              </Grid>
              {!isMd && <Info />}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUpForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      borderRadius: 4,
      boxShadow: colors.boxShadow,
    },
    planContent: {
      borderLeft: `2px solid ${colors.lightGray}`,
      [theme.breakpoints.down('md')]: {
        borderLeft: 0,
      },
    },
    planText: {
      fontWeight: 700,
      fontSize: '1.1rem',
      marginBottom: theme.spacing(2),
    },
    amountText: {
      fontWeight: 700,
      fontSize: '1.5rem',
      marginBottom: theme.spacing(2),
    },
    itemText: {
      marginBottom: theme.spacing(2),
    },
    image: {
      marginRight: theme.spacing(2),
    },
    showMoreText: {
      color: colors.blue,
      fontWeight: 300,
      fontSize: '0.9rem',
      marginTop: theme.spacing(1),
      cursor: 'pointer',
      userSelect: 'none',
    },
    totalContainer: {
      backgroundColor: colors.lightBlue,
      borderRadius: 4,
    },
    totalText: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
    paymentText: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  })
);
