const colors = {
  gray: '#262B39',
  black: '#29323F',
  white: '#FFFFFF',
  blue: '#73C6FD',
  boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  lightGray: 'rgba(237, 237, 237, 0.42)',
  lightBlue: '#F7FAFD',
  blueMain: '#3F51B5',
  darkBlue: '#262A37',
  darkGray: '#323747',
  dark: '#434650',
  lightWhite: '#FAFAFA',
  lightBlack: '#716C80',
  red: '#F44336',
};

export default colors;
