import { FC, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, TextField, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useFormik } from 'formik';
import { callable } from '@dayone/firebase';
import { useStripe } from '@stripe/react-stripe-js';

import { Button } from '../../shared/components/button';
import { SignUpForm } from '../../shared/components/signUpForm';
import { ProgressIndicator } from '../../shared/components/progressIndicator';
import colors from '../../shared/ultilities/colors';
import firebaseApp from '../../configs/firebaseApp';
import formatPhoneNumber from './helpers/formatPhoneNumber';

import { IPaymentData } from '../../contexts/app.context';
import validationSchema from './helpers/validationSchema';
import { AppContext } from '../../contexts/app.context';
import analytics from 'shared/ultilities/analytics';
import AnalyticsEvents from 'shared/ultilities/events';

const Payment: FC = () => {
  const { data, setPayment } = useContext(AppContext);
  const navigate = useNavigate();
  const stripe = useStripe();

  const initialValues: IPaymentData = data.payment ?? {
    fullName: '',
    creditCardNumber: '',
    creditCardExpireDate: '',
    cvv: '',
    zipCode: '',
    streetName: '',
    office: '',
    city: '',
    state: '',
  };

  const onSubmit = useCallback(
    async (values: IPaymentData) => {
      analytics.logEvent(AnalyticsEvents.registration_subscribe);
      setPayment(values);
      setSubmitting(true);
      if (firebaseApp) {
        const submitData: any = {
          registration: {
            ...data.registration,
            mobileNumber: formatPhoneNumber(data.registration?.mobileNumber, data.registration?.countryCode?.iso),
          },
          workSpace: data.workSpace,
          payment: values,
        };
        const res: any = await callable.payment(firebaseApp as any, submitData);

        const {
          requireActions,
          clientSecret,
          success,
          error,
          orderId,
          transactionId,
          time,
          paymentType,
          needToResetPassword,
        } = res.data || {};
        if (requireActions && clientSecret) {
          const { error, paymentIntent }: any = await stripe?.handleCardAction(clientSecret);
          if (error) {
            navigate('/payment-fail');
          } else {
            const res: any = await callable.confirmPayment(
              firebaseApp as any,
              {
                paymentIntentId: paymentIntent.id,
                orderId,
              } as any
            );

            const {
              success,
              error,
              orderId: confirmOrderId,
              transactionId,
              time,
              paymentType,
              needToResetPassword,
            } = res.data || {};

            if (error) {
              navigate('/payment-fail');
            } else if (success) {
              navigate('/payment-success', {
                state: { orderId: confirmOrderId, transactionId, time, paymentType, needToResetPassword },
              });
            }
          }
        } else if (error) {
          navigate('/payment-fail');
        } else if (success) {
          navigate('/payment-success', { state: { orderId, transactionId, time, paymentType, needToResetPassword } });
        }
      }
      setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.registration, data.workSpace]
  );

  const {
    values,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    !data.workSpace && navigate('/create-workspace');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const styles = useStyles();

  return (
    <SignUpForm isPayment>
      <form>
        {isSubmitting && <ProgressIndicator />}
        <Typography className={styles.text}>Payment information</Typography>
        {/* <Box marginBottom={4}>
          <Autocomplete
            autoSelect
            fullWidth
            options={countryCode}
            onChange={(_event, value) => setFieldValue('countryCode', value)}
            value={values.countryCode}
            defaultValue={values.countryCode}
            getOptionLabel={(option) => `${labelOrIso(option)} (+${option.code} )`}
            getOptionSelected={(option, value) => (value ? option.code === value.code : true)}
            renderOption={(option) => (
              <Box display="flex" alignItems="center" textOverflow="ellipsis">
                {`${countryToFlag(option.iso)} ${labelOrIso(option)} (+${option.code})`}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                error={touched.countryCode && Boolean(errors.countryCode)}
                helperText={touched.countryCode && errors.countryCode}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box> */}
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Full name"
            variant="outlined"
            name="fullName"
            label="Full name"
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur('fullName')}
            error={touched.fullName && !!errors.fullName}
            helperText={touched.fullName && errors.fullName}
          />
        </Box>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Credit card number"
            variant="outlined"
            name="creditCardNumber"
            label="Credit card number"
            value={values.creditCardNumber}
            onChange={handleChange}
            onBlur={handleBlur('creditCardNumber')}
            error={touched.creditCardNumber && !!errors.creditCardNumber}
            helperText={touched.creditCardNumber && errors.creditCardNumber}
          />
        </Box>
        <Box marginBottom={4}>
          <Box display="flex" alignItems="center">
            <Box marginRight={4} width="60%">
              <TextField
                fullWidth
                placeholder="MM/YY"
                variant="outlined"
                name="creditCardExpireDate"
                label="MM/YY"
                value={values.creditCardExpireDate}
                onChange={handleChange}
                onBlur={handleBlur('creditCardExpireDate')}
                error={touched.creditCardExpireDate && !!errors.creditCardExpireDate}
              />
            </Box>
            <Box width="40%">
              <TextField
                fullWidth
                placeholder="CVV"
                variant="outlined"
                name="cvv"
                label="CVV"
                value={values.cvv}
                onChange={handleChange}
                onBlur={handleBlur('cvv')}
                error={touched.cvv && !!errors.cvv}
              />
            </Box>
          </Box>
          {((touched.creditCardExpireDate && errors.creditCardExpireDate) || (touched.cvv && errors.cvv)) && (
            <Box display="flex" alignItems="center">
              <Box marginRight={2} width="60%">
                {touched.creditCardExpireDate && errors.creditCardExpireDate && (
                  <Typography className={styles.error}>{errors.creditCardExpireDate}</Typography>
                )}
              </Box>
              <Box width="40%">
                {touched.cvv && errors.cvv && <Typography className={styles.error}>{errors.cvv}</Typography>}
              </Box>
            </Box>
          )}
        </Box>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Zip code"
            variant="outlined"
            name="zipCode"
            label="Zip code"
            value={values.zipCode}
            onChange={handleChange}
            onBlur={handleBlur('zipCode')}
            error={touched.zipCode && !!errors.zipCode}
            helperText={touched.zipCode && errors.zipCode}
          />
        </Box>
        <Typography className={styles.text}>Billing address</Typography>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Street name"
            variant="outlined"
            name="streetName"
            label="Street name"
            value={values.streetName}
            onChange={handleChange}
            onBlur={handleBlur('streetName')}
            error={touched.streetName && !!errors.streetName}
            helperText={touched.streetName && errors.streetName}
          />
        </Box>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Apt, office, suite"
            variant="outlined"
            name="office"
            label="Apt, office, suite"
            value={values.office}
            onChange={handleChange}
            onBlur={handleBlur('office')}
            error={touched.office && !!errors.office}
            helperText={touched.office && errors.office}
          />
        </Box>
        <Box marginBottom={4}>
          <Box display="flex" alignItems="center">
            <Box marginRight={4} width="60%">
              <TextField
                fullWidth
                placeholder="City"
                variant="outlined"
                name="city"
                label="City"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur('city')}
                error={touched.city && !!errors.city}
              />
            </Box>
            <Box width="40%">
              <TextField
                fullWidth
                placeholder="State"
                variant="outlined"
                name="state"
                label="State"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur('state')}
                error={touched.state && !!errors.state}
              />
            </Box>
          </Box>
          {((touched.city && errors.city) || (touched.state && errors.state)) && (
            <Box display="flex" alignItems="center">
              <Box marginRight={2} width="60%">
                {touched.city && errors.city && <Typography className={styles.error}>{errors.city}</Typography>}
              </Box>
              <Box width="40%">
                {touched.state && errors.state && <Typography className={styles.error}>{errors.state}</Typography>}
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="stretch" marginBottom={8}>
          <Button
            text="Agree and subscribe"
            onClick={handleSubmit}
            backgroundColor={colors.blueMain}
            color={colors.white}
            paddingVertical={16}
            disabled={isSubmitting}
          />
        </Box>
      </form>
    </SignUpForm>
  );
};

export default Payment;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 700,
      fontSize: '0.9rem',
      marginBottom: theme.spacing(2),
    },
    error: {
      color: colors.red,
      fontSize: '0.75rem',
      marginLeft: 14,
      marginTop: 3,
    },
  })
);
