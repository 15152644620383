import { FC, useCallback, useMemo } from 'react';
import { Box, Typography, useTheme, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import sections from '../../ultilities/sections';
import colors from '../../ultilities/colors';
import logoLight from '../../../assets/footer/logoLight.svg';
import medium from '../../../assets/footer/medium.svg';
import youtube from '../../../assets/footer/youtube.svg';
import linkedin from '../../../assets/footer/linkedin.svg';
import mail from '../../../assets/footer/mail.svg';
import appstore from '../../../assets/footer/appstore.svg';
import playstore from '../../../assets/footer/playstore.svg';

const Footer: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();
  const dayoneTypo = <Typography style={{ color: colors.white }}>&#8287;DayOne</Typography>;

  const navigateToSection = useCallback(
    (url: string, id: string) => {
      navigate(url, { state: { id: id } });
    },
    [navigate]
  );

  const navigateItems = useMemo(() => {
    const items = [
      { text: 'Pricing', onClick: () => navigateToSection('/pricing', sections.pricing) },
      { text: 'Why DayOne?', onClick: () => navigateToSection('/', sections.explanation) },
      { text: `FAQ's`, onClick: () => navigateToSection('/pricing', sections.faq) },
      { text: 'Support', onClick: () => window.open('mailto:hello@weareday.one') },
    ];
    return (
      <Box display="flex">
        {items.map((item) => (
          <Typography key={item.text} onClick={item.onClick} className={styles.navigateItem}>
            {item.text}
          </Typography>
        ))}
      </Box>
    );
  }, [navigateToSection, styles.navigateItem]);

  return (
    <Box className={styles.container} marginTop={theme.spacing(0.5)}>
      <img src={logoLight} alt="logo-light" />
      <Box className={styles.socials}>
        <a target="_blank" rel="noreferrer" href="https://medium.com/wearedayone">
          <img src={medium} className={styles.socialItem} alt="medium" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCVzYhDVr6Vd1yvpuLqGcZ-Q">
          <img src={youtube} className={styles.socialItem} alt="youtube" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/wearedayone/">
          <img src={linkedin} className={styles.socialItem} alt="linkedin" />
        </a>
        <a target="_blank" rel="noreferrer" href="mailto:hello@weareday.one">
          <img src={mail} className={styles.socialItem} alt="whatsapp" />
        </a>
      </Box>
      <Box className={styles.socials}>
        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=expert.dayone.app">
          <img src={playstore} className={styles.downloadItem} alt="Google Play Store" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/pl/app/dayone-productivity/id1548855109">
          <img src={appstore} className={styles.downloadItem} alt="App Store" />
        </a>
      </Box>
      <Box
        style={{
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          borderTopColor: colors.darkGray,
          width: '100%',
          height: 1,
          margin: theme.spacing(2),
        }}
      />
      {navigateItems}
      <Box className={styles.copyright}>
        <Typography>Copyright © 2022</Typography>
        {dayoneTypo}
        <Typography>. All Rights Reserved by </Typography>
        {dayoneTypo}
      </Box>
    </Box>
  );
};

export default Footer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3.5),
      backgroundColor: colors.darkBlue,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    socials: { marginTop: theme.spacing(3.5) },
    socialItem: { margin: theme.spacing(2.5) },
    downloadItem: {
      width: '120px',
      margin: '0 10px',
    },
    navigateItem: {
      FontFamily: 'Inter',
      FontStyle: 'Regular',
      FontSize: '0.8em',
      color: colors.white,
      margin: theme.spacing(2),
      cursor: 'pointer',
    },
    copyright: {
      color: '#B0B2B9',
      marginTop: theme.spacing(5),
      display: 'flex',
    },
  })
);
