import { FC } from 'react';
import { Box, Typography, useTheme, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
// import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import sections from '../../../shared/ultilities/sections';
import colors from '../../../shared/ultilities/colors';

import step1 from '../../../assets/getStarted/step1.svg';
import step2 from '../../../assets/getStarted/step2.svg';
import step3 from '../../../assets/getStarted/step3.svg';
import arrow from '../../../assets/getStarted/arrow.svg';

const GetStarted: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isMediumOrBellow = useMediaQuery(theme.breakpoints.down('sm'));

  const direction = isMediumOrBellow ? 'column' : 'row';
  return (
    <Box
      id={sections.getStarted}
      marginTop={isMobile ? 5 : 15}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {/* <Typography className={styles.title}>AS EASY AS ABC</Typography> */}
      <Typography className={styles.headline}>How to get started with DayOne</Typography>
      <Typography className={styles.description}>
        Subscribe an account, create teams and invite members. Upload materials for your team to review.
        <br />
        Start delegating tasks and post announcement to keep everyone in the loop
      </Typography>
      <Box display="flex" flexDirection={direction} alignItems="center" margin={-2.5}>
        <img alt="Login" src={step1} style={{ margin: theme.spacing(2.5), width: isMediumOrBellow ? '80%' : '25%' }} />
        <img
          alt="arrow"
          src={arrow}
          style={{ margin: theme.spacing(2.5), transform: isMediumOrBellow ? 'rotate(90deg)' : 'none' }}
        />
        <img alt="Invite" src={step2} style={{ margin: theme.spacing(2.5), width: isMediumOrBellow ? '80%' : '25%' }} />
        <img
          alt="arrow"
          src={arrow}
          style={{ margin: theme.spacing(2.5), transform: isMediumOrBellow ? 'rotate(90deg)' : 'none' }}
        />
        <img alt="Manage" src={step3} style={{ margin: theme.spacing(2.5), width: isMediumOrBellow ? '80%' : '25%' }} />
      </Box>
      {/* 
      <Button variant="contained" size="large" className={styles.button} onClick={() => navigate('/registration')}>
        <Typography className={styles.buttonText}>Get Started</Typography>
      </Button> */}
    </Box>
  );
};

export default GetStarted;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: colors.darkBlue,
      fontSize: '1em',
      letterSpacing: 1.4,
      marginBottom: theme.spacing(1.5),
      textAlign: 'center',
    },
    headline: {
      color: colors.darkBlue,
      fontSize: '2.6rem',
      letterSpacing: 0,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    description: {
      fontSize: 20,
      fontWeight: 400,
      textAlign: 'center',
      color: '#716C80',
      marginBottom: theme.spacing(3),
    },
    button: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
      borderRadius: 20,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5.5),
      marginTop: theme.spacing(7),
    },
    buttonText: { textTransform: 'capitalize', fontSize: '1.1em', fontWeight: 600 },
  })
);
