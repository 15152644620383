import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';

import { AppContext } from '../../contexts/app.context';

import { Button } from '../../shared/components/button';
import colors from '../../shared/ultilities/colors';

const AlreadyRegistered: FC = () => {
  const { data, onLogin } = useContext(AppContext);
  const styles = useStyles();
  const navigate = useNavigate();

  const back = useCallback(() => {
    navigate('/registration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCreateWorkSpace = useCallback(() => {
    navigate('/create-workspace');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !data.registration && navigate('/registration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box paddingY={8}>
      <Grid container className={styles.container}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box paddingX={4}>
            <Typography className={styles.title}>You have already registered before</Typography>
            <Typography className={styles.text}>Oops. Looks like you already have an account.</Typography>
            <Typography className={styles.text}>
              We couldnt create a new DayOne account because there is already an account with the email address.
            </Typography>
            <Box padding={4} marginY={4} className={styles.box}>
              {/* <Typography className={styles.title2}>What to do next</Typography>
              <Typography className={styles.text}>
                If you like to create another workspace using the same email, simply{' '}
                <span className={styles.clickHereText} onClick={goToCreateWorkSpace}>
                  click here.
                </span>
              </Typography> */}
              <Box display="flex" justifyContent="center" paddingY={4}>
                <Box width="70%" display="flex" flexDirection="column" alignItems="stretch">
                  <Button
                    text="Log In Now"
                    color={colors.white}
                    backgroundColor={colors.black}
                    onClick={onLogin}
                    paddingVertical={6}
                  />
                </Box>
              </Box>
              <Typography className={styles.text}>
                Didn’t sign up for a new DayOne account?{' '}
                <a href="mailto: support@wearedayone" className={styles.letUsKnowText}>
                  Let us know.
                </a>
              </Typography>
            </Box>

            <Typography className={styles.backBtn} onClick={back}>
              &#8592; Back
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlreadyRegistered;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: theme.spacing(1.5),
    },
    title2: {
      fontWeight: 600,
      fontSize: '1.3rem',
    },
    text: {
      fontSize: '1.1rem',
      fontWeight: 400,
      lineHeight: '2.5rem',
    },
    clickHereText: {
      color: colors.blueMain,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    letUsKnowText: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: colors.black,
    },
    box: {
      backgroundColor: colors.lightWhite,
      borderRadius: 4,
    },
    backBtn: {
      fontWeight: 700,
      cursor: 'pointer',
    },
  })
);
