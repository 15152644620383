import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { HelmetProvider } from 'react-helmet-async';

import './App.css';
import NavigationRoute from './navigations';
import { AppContextProvider } from './contexts/app.context';
import ClarityFunction from 'shared/ultilities/ClarityFunction';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

function App() {
  return (
    <Elements stripe={stripe}>
      <HelmetProvider>
        <AppContextProvider>
          <ClarityFunction />
          <NavigationRoute />
        </AppContextProvider>
      </HelmetProvider>
    </Elements>
  );
}

export default App;
