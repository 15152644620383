import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { callable } from '@dayone/firebase';
import { useSnackbar } from 'notistack';

import { Button } from '../../shared/components/button';
import { SignUpForm } from '../../shared/components/signUpForm';
import { ProgressIndicator } from '../../shared/components/progressIndicator';
import colors from '../../shared/ultilities/colors';
import industries from './helpers/industries.json';
import numberOfEmployees from './helpers/numberOfEmployees.json';
import firebaseApp from '../../configs/firebaseApp';
import formatPhoneNumber from './helpers/formatPhoneNumber';

import { IWorkspaceData } from '../../contexts/app.context';
import { workSpaceSchema } from './helpers/validationSchema';
import { AppContext } from '../../contexts/app.context';
import analytics from 'shared/ultilities/analytics';
import AnalyticsEvents from 'shared/ultilities/events';

const CreateWorkSpace: FC = () => {
  const { data, setWorkSpace } = useContext(AppContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: IWorkspaceData = data.workSpace ?? {
    businessName: '',
    industry: 'f_and_b',
    companySize: 1,
  };

  const styles = useStyles();

  const onSubmit = useCallback(async (values: IWorkspaceData) => {
    setSubmitting(true);
    analytics.logEvent(AnalyticsEvents.registration_enter_business_info);
    try {
      setWorkSpace(values);
      if (firebaseApp) {
        const submitData: any = {
          registration: {
            ...data.registration,
            mobileNumber: formatPhoneNumber(data.registration?.mobileNumber, data.registration?.countryCode?.iso),
          },
          workSpace: values,
          // payment: values,
        };
        const res: any = await callable.payment(firebaseApp as any, submitData);

        const { success, error } = res.data || {};

        if (error) {
          navigate('/payment-failed');
        } else if (success) {
          navigate('/payment-success');
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, isSubmitting, touched, errors, handleChange, handleBlur, handleSubmit, setSubmitting } = useFormik({
    initialValues,
    validationSchema: workSpaceSchema,
    onSubmit,
  });

  useEffect(() => {
    !data.registration && navigate('/registration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SignUpForm>
      <form onSubmit={handleSubmit}>
        {isSubmitting && <ProgressIndicator />}
        <Typography className={styles.text}>Payment information</Typography>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            placeholder="Business name"
            variant="outlined"
            name="businessName"
            label="Business name"
            value={values.businessName}
            onChange={handleChange}
            onBlur={handleBlur('businessName')}
            error={touched.businessName && !!errors.businessName}
            helperText={touched.businessName && errors.businessName}
          />
        </Box>
        <Box marginBottom={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Industry</InputLabel>
            <Select
              name="industry"
              label="Industry"
              displayEmpty
              value={values.industry}
              onChange={handleChange}
              error={touched.industry && Boolean(errors.industry)}>
              <MenuItem disabled value="">
                <em>Select industry</em>
              </MenuItem>
              {industries.map((item, index) => (
                <MenuItem key={index + ''} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {touched.industry && <FormHelperText error>{errors.industry}</FormHelperText>}
          </FormControl>
        </Box>
        <Box marginBottom={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Number of employees</InputLabel>
            <Select
              name="companySize"
              label="Number of employees"
              displayEmpty
              value={values.companySize}
              onChange={handleChange}
              error={touched.companySize && Boolean(errors.companySize)}>
              <MenuItem disabled value="">
                <em>Select number of employees</em>
              </MenuItem>
              {numberOfEmployees.map((item, index) => (
                <MenuItem key={index + ''} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {touched.companySize && <FormHelperText error>{errors.companySize}</FormHelperText>}
          </FormControl>
        </Box>
        <Typography className={styles.termText}>
          By clicking Continue, you agree to our Terms and that you have read our Data Use Policy, including our Cooke
          Use. DayOne may send you communications; you may change your preferences by emailing{' '}
          <span className={styles.supportText}>support.</span>
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="stretch" marginY={8}>
          <Button
            text="Continue"
            onClick={handleSubmit}
            backgroundColor={colors.blueMain}
            color={colors.white}
            paddingVertical={16}
            disabled={isSubmitting}
          />
        </Box>
      </form>
    </SignUpForm>
  );
};

export default CreateWorkSpace;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 700,
      fontSize: '0.9rem',
      marginBottom: theme.spacing(2),
    },
    termText: {
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    supportText: {
      color: colors.blueMain,
    },
  })
);
