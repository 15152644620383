import { FC } from 'react';
import { Typography, createStyles, makeStyles, Theme } from '@material-ui/core';

const StartText: FC = () => {
  const styles = useStyles();

  return (
    <>
      <Typography className={styles.text1} align="center">
        TELL US ABOUT YOUR BUSINESS
      </Typography>
      <Typography className={styles.text2} align="center">
        Start Using DayOne Today{' '}
      </Typography>
    </>
  );
};

export default StartText;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text1: {
      fontWeight: 400,
      fontSize: '0.9rem',
      marginBottom: theme.spacing(1),
    },
    text2: {
      fontWeight: 400,
      fontSize: '2.25rem',
      marginBottom: theme.spacing(6),
    },
  })
);
