import { FC } from 'react';
import { Box, useTheme, useMediaQuery, Grid } from '@material-ui/core';
// import KentTeoQuote from '../../../assets/realLifeApplication/KentTeoQuote.png';
import sections from '../../../shared/ultilities/sections';
import YoutubeEmbed from './YoutubeEmbed';

const RealLifeApplication: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box id={sections.realLifeApplication} marginTop={isMobile ? 5 : 15}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <YoutubeEmbed embedId="SlwGp2mqYfY" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RealLifeApplication;
