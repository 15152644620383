import FirebaseConfig, {
  appCheck,
  emulatorAuth,
  emulatorFirestore,
  emulatorFunctions,
  emulatorStorage,
  emulatorCustomStorage,
} from '@dayone/firebase';

import env from '../shared/ultilities/environment';

const firebaseApp = FirebaseConfig(env.FIREBASE.config);
console.log(env.FIREBASE.config);

if (firebaseApp) {
  // Setup appCheck
  appCheck(firebaseApp, env.RECAPTCHA.key, env.RECAPTCHA.enableAppCheckDebug, env.RECAPTCHA.debugToken);

  // If using emulator
  if (env.FIREBASE.useFirebaseEmulator) {
    // eslint-disable-next-line no-console
    console.group('Emulator');
    emulatorAuth(firebaseApp);
    emulatorFirestore(firebaseApp);
    emulatorFunctions(firebaseApp);
    emulatorStorage(firebaseApp);
    emulatorCustomStorage(firebaseApp, Object.values(env.FIREBASE.storageBucketConfig));
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}

export default firebaseApp;
