import { FC, useState, useCallback } from 'react';
import {
  Box,
  Collapse,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Icon,
} from '@material-ui/core';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import parse from 'html-react-parser';

import questions from '../helpers/questions.json';
import colors from '../../../shared/ultilities/colors';

const Questions: FC = () => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleOpen = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes(openIndexes.filter((item) => item !== index));
        return;
      }

      setOpenIndexes([...openIndexes, index]);
    },
    [openIndexes]
  );

  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box marginTop={isMobile ? 5 : 15} id="faq">
      <Typography align="center" className={styles.title}>
        FAQs
      </Typography>
      {questions.map((question, index) => (
        <Box key={index} display="flex" alignItems="center">
          <Box style={{ cursor: 'pointer' }} flexGrow={1} marginRight={6} onClick={() => toggleOpen(index)}>
            {!index && <hr />}
            <Typography className={styles.questionText}>{question.text}</Typography>
            <Collapse in={openIndexes.includes(index)} timeout="auto" unmountOnExit>
              <Typography className={styles.answerText}>{parse(question.answer)}</Typography>
            </Collapse>
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </Box>
          <IconButton onClick={() => toggleOpen(index)}>
            {openIndexes.includes(index) ? (
              <Icon className={styles.toggleIcon} style={{ fontSize: 30 }}>
                remove
              </Icon>
            ) : (
              <Icon className={styles.toggleIcon} style={{ fontSize: 30 }}>
                add
              </Icon>
            )}
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default Questions;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 400,
      fontSize: '2.25rem',
      marginBottom: theme.spacing(5),
    },
    questionText: {
      fontSize: '0.9rem',
      fontWeight: 550,
      margin: `${theme.spacing(2)}px 0px`,
      paddingLeft: theme.spacing(5),
      color: colors.darkBlue,
    },
    answerText: {
      fontSize: '0.9rem',
      fontWeight: 400,
      margin: `${theme.spacing(2)}px 0px`,
      paddingLeft: theme.spacing(5),
      color: colors.darkBlue,
    },
    toggleIcon: {
      cursor: 'pointer',
      color: 'black',
    },
  })
);
