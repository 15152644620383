import { FC } from 'react';
import { Box, Typography, useTheme, createStyles, makeStyles, useMediaQuery, Theme } from '@material-ui/core';

import sections from '../../ultilities/sections';

import { CustomerIcons } from '../../components/customerIcons';

interface Props {
  text: string;
}

const Customers: FC<Props> = ({ text }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box id={sections.customers} marginTop={isMobile ? 5 : 15}>
      <Typography className={styles.title}>{text}</Typography>
      <CustomerIcons />
    </Box>
  );
};

export default Customers;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
  })
);
