import { FC, useEffect } from 'react';
import { Box } from '@material-ui/core';

import PricingTable from './components/PricingTable';
import Questions from './components/Questions';
import { Customers } from '../../shared/components/customers';
import { Welcome } from '../../shared/components/welcome';
import { Footer } from '../../shared/components/footer';
import { useLocation } from 'react-router-dom';
import scrollToElement from '../../shared/ultilities/scrollToElement';

const Pricing: FC = () => {
  const { state } = useLocation();
  const { id }: any = state || {};
  useEffect(() => {
    if (id) {
      scrollToElement(id);
    }
  }, [id]);
  return (
    <Box>
      <Box mx={{ xs: 1, sm: 5, lg: 15 }}>
        <PricingTable />
        <Customers text="Start Empowering your Business today" />
        <Questions />
        <Welcome />
      </Box>
      <Footer />
    </Box>
  );
};

export default Pricing;
