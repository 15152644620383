import env from './shared/ultilities/environment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Inter-Regular.ttf';
import './assets/fonts/Inter-Medium.ttf';
import './assets/fonts/Inter-VariableFont_slnt,wght.ttf';

import './index.scss';
import analytics from 'shared/ultilities/analytics';

Sentry.init({
  dsn: env.SENTRY.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: env.SENTRY.tracesSampleRate,
});

analytics.config();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
