import { FC, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  createStyles,
  makeStyles,
  useMediaQuery,
  Theme,
  withStyles,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from '../../../shared/ultilities/colors';
import features from '../../../shared/ultilities/keyFeatures';
import sections from '../../../shared/ultilities/sections';

const KeyFeatures: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [expanded, setExpanded] = useState<string | false>(features[0].name);
  const [videoId, setVideoId] = useState<string>(features[0].videoId);

  const handleExpandedChange = (feature: { name: string; videoId: string; intro: string }) => {
    setExpanded(feature.name);
    setVideoId(feature.videoId);
  };
  return (
    <Box id={sections.keyFeatures} marginTop={isMobile ? 5 : 15}>
      {/* <Typography className={styles.title}>KEY FEATURES</Typography> */}
      <Typography className={styles.headline}>The Best Features For You</Typography>
      <Grid container spacing={5} style={{ alignItems: 'center' }}>
        <Grid xs={12} md={5} item container spacing={2}>
          {features.map((feature) => (
            <FeatureItem
              key={feature.name}
              {...feature}
              expanded={expanded === feature.name}
              onChangeExpand={() => handleExpandedChange(feature)}
            />
          ))}
        </Grid>
        <Grid xs={12} md={7} item>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <video autoPlay controls muted loop width="100%" src={videoId}></video>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const FeatureItem = (props: {
  name: string;
  intro: string;
  expanded: boolean;
  onChangeExpand: (event: any, isExpanded: boolean) => void;
}) => {
  const styles = useStyles();
  return (
    <Accordion expanded={props.expanded} onChange={props.onChangeExpand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props.name}-content`}
        id={`${props.name}-header`}
      >
        <Typography className={styles.featureName}>{props.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={styles.featureIntro}>{props.intro}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default KeyFeatures;

const Accordion = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #667D92',
    borderRadius: 'initial',
    fontFamily: 'Inter',
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 'initial',
    },
    '&:last-child': {
      borderRadius: 'initial',
      borderBottom: '1px solid #667D92',
    },
  },
  expanded: {
    borderTop: '3px solid #667D92',
    fontVariationSettings: `'wght' 500`,
    borderRadius: 'initial',
    marginTop: '0px !important',
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: colors.darkBlue,
      fontSize: '1em',
      letterSpacing: 1.4,
      marginBottom: theme.spacing(1.5),
      textAlign: 'center',
    },
    headline: {
      color: colors.darkBlue,
      fontSize: '2.6rem',
      letterSpacing: 0,
      marginBottom: theme.spacing(5),
      textAlign: 'center',
    },
    featureName: {
      fontSize: '1.3em',
      letterSpacing: 0,
      fontFamily: 'Inter',
    },
    featureIntro: {
      fontSize: '1em',
      color: '#716C80',
      fontFamily: 'Inter',
      fontVariationSettings: `'wght' 400`,
    },
  })
);
