import { FC, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, useTheme, useMediaQuery, createStyles, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import { sendPasswordResetEmail } from '@dayone/firebase';

import colors from '../../shared/ultilities/colors';
import success from '../../assets/payment/success.svg';

import { AppContext } from '../../contexts/app.context';
import { Button } from '../../shared/components/button';
import { StartText } from '../../shared/components/startText';

const PaymentSuccess: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  // const navigate = useNavigate();
  // const { state } = useLocation() as any;
  const { data, onLogin } = useContext(AppContext);
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box paddingY={8}>
      <Grid container className={styles.container}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <StartText />
          <Box paddingX={4}>
            <Box paddingX={isXs ? 4 : 16} paddingY={8} className={styles.wrapper}>
              {/* <Typography className={styles.text} align="center">
                Payment successful! Thank you for your purchase!
              </Typography>
              <Typography className={styles.text} align="center">
                Your payment of USD $99.00 has been received.
              </Typography>

              <Box paddingY={4}>
                <Typography className={styles.text} align="center">
                  Order ID: {orderId}
                </Typography>
                <Typography className={styles.text} align="center">
                  Transaction ID: {transactionId}
                </Typography>
              </Box>
              <Typography align="center" className={styles.title}>
                Payment Details:
              </Typography>

              <Box padding={2} className={styles.content}>
                <Box display="flex" justifyContent="space-between">
                  <span>Card Holder:</span>
                  <span>{payment?.fullName}</span>
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={0.8}>
                  <span>Payment Type:</span>
                  <span>{paymentType}</span>
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={0.8}>
                  <span>Mobile:</span>
                  <span>{registration?.mobileNumber}</span>
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={0.8}>
                  <span>Email:</span>
                  <span>{registration?.email}</span>
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={0.8}>
                  <span>Transaction Date:</span>
                  <span>{moment(new Date(time)).format('DD/MM/YYYY HH:mm')}</span>
                </Box>
              </Box> */}

              {/* <Typography className={styles.smallText}>*A copy of the invoice will be emailed to you</Typography> */}
              <Box display="flex" justifyContent="center" paddingTop={4}>
                <img src={success} alt="success" />
              </Box>

              <Box display="flex" justifyContent="center" paddingY={4}>
                <Box
                  width={isMd ? '70%' : '50%'}
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  marginTop={-4}>
                  <Button
                    text="Login"
                    color={colors.white}
                    backgroundColor={colors.black}
                    onClick={onLogin}
                    paddingVertical={8}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentSuccess;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      borderRadius: 4,
      boxShadow: colors.boxShadow,
      color: colors.lightBlack,
    },
    content: {
      backgroundColor: colors.lightWhite,
      borderRadius: 4,
    },
    text: {
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: '2rem',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.1rem',
      marginBottom: theme.spacing(1),
    },
    smallText: {
      fontWeight: 400,
      fontSize: '0.7rem',
      marginTop: theme.spacing(0.5),
    },
  })
);
