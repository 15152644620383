import Joyce from '../../assets/reviews/Joyce.png';
import Kent from '../../assets/reviews/Kent.png';
import Joey from '../../assets/reviews/Joey.png';
import Joy from '../../assets/reviews/Joy.png';
import Jessica from '../../assets/reviews/Jessica.png';

const reviews = [
  {
    name: 'Joyce',
    role: 'Operations Manager ThinkersBox',
    image: Joyce,
    quote:
      "“DayOne is a platform where you can store FAQs, making it easy for staff to retrieve information. It's a centralized place for staff knowledge and training. DayOne's mobile platform has a clean interface and offers users ease of accessibility”",
  },
  {
    name: 'Kent Teo',
    role: 'Founder of Invade',
    image: Kent,
    quote:
      '“With DayOne, work that used to take 5 members now gets reduced to just 1 or 2 members. Communications is clear and teams work better.”',
  },
  {
    name: 'Joey Lee',
    role: 'Co-founder of Poke Theory',
    image: Joey,
    quote:
      "“As a franchise based business, SOPs are incredibly crucial. This is why I was drawn to DayOne's functions as it helps me to keep track and manage my company information and communications!”",
  },
  {
    name: 'Joy',
    role: 'Partner at Maxi Coffee Bar',
    image: Joy,
    quote:
      '“Dayone is a communication app for operational teams and it helps businesses to achieve operational excellence”',
  },
  {
    name: 'Jessica Braun',
    role: 'Operation and Training  Manager',
    image: Jessica,
    quote:
      '“DayOne helps managing tasks & allow operational teams be autonomous with digital tools & training material library to support them daily. It provides a good overview of teams performance & the flow of task in operations. I can see the status of each outlet in a blink, it’s especially helpful when managing various branches.”',
  },
  // {
  //   name: 'Mao',
  //   role: 'Owner of Pints & Cones',
  //   image: Jessica,
  //   quote: '“DayOne is really helpful in the delegation and tracking of staff tasks!”',
  // },
];

export default reviews;
