import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, useTheme } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import colors from '../../ultilities/colors';

export default function Carousel(props: {
  children: any[];
  itemToShow?: number;
  offsetRation?: number;
  showNaviButtons?: boolean;
  autoplay?: boolean;
  slideTime?: number;
}) {
  const theme = useTheme();

  //total item length
  const count = props.children?.length ?? 0;

  //number of item show on screen
  const itemToShow = props.itemToShow ?? 1;

  //ration of padding left and right
  const offsetRation = props.offsetRation ?? 0;

  //show next, prev buttons
  const showNaviButtons = props.showNaviButtons ?? false;

  //auto next slide
  const autoplay = props.autoplay ?? true;
  // time per slide
  const slideTime = props.slideTime ?? 8000;

  // width of each slide
  const [itemWidth, setWidth] = useState(0);
  //width of padding left and right
  const [offsetWidth, setOffsetWidth] = useState(0);

  //paging dots
  const dotsCount = count > itemToShow ? count - itemToShow + 1 : 1;
  const dots: any[] = [];
  const [currentIndex, setCurrentIndex] = useState(0);
  for (let i = 0; i < dotsCount; i++) {
    const color = currentIndex === i ? colors.darkBlue : colors.white;
    dots.push(
      <Box
        key={i.toString()}
        width={theme.spacing(2)}
        height={theme.spacing(2)}
        margin={0.25}
        borderRadius={'50%'}
        bgcolor={color}
        onClick={() => onDotClick(i)}
      />
    );
  }

  //slice container ref
  const sliceContainerRef = useRef<HTMLDivElement>(null);
  //calculate slide width and padding width
  useEffect(() => {
    function onResize() {
      if (sliceContainerRef.current) {
        let containerWidth = (sliceContainerRef.current as any)?.offsetWidth ?? 0;
        let width = containerWidth / itemToShow;
        if (offsetRation) {
          width = containerWidth / (itemToShow + offsetRation * 2);
          setOffsetWidth(width * offsetRation);
        } else {
          setOffsetWidth(0);
        }
        setWidth(width);
      }
    }
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [setWidth, itemToShow, sliceContainerRef.current?.offsetWidth, offsetRation]);

  //scroll follow current index
  useEffect(() => {
    sliceContainerRef.current?.scroll({
      top: 0,
      left: itemWidth * currentIndex - offsetWidth,
      behavior: 'smooth',
    });
  }, [itemWidth, offsetWidth, currentIndex]);

  //reset current index after change screen ration
  useEffect(() => {
    setCurrentIndex(0);
  }, [itemToShow]);

  //handle dot click
  const onDotClick = (index: any) => {
    setCurrentIndex(index);
  };
  const prev = useCallback(() => {
    setCurrentIndex((index) => (index > 0 ? index - 1 : dotsCount - 1));
  }, [setCurrentIndex, dotsCount]);
  const next = useCallback(() => {
    setCurrentIndex((index) => (index < dotsCount - 1 ? index + 1 : 0));
  }, [setCurrentIndex, dotsCount]);

  //handle autoplay
  useInterval(slideTime, next, autoplay);

  //handle swipes and drags
  const { touchStart, touchMove, touchEnd } = useSwipeDetection(next, prev);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        {showNaviButtons && (
          <Box style={{ position: 'absolute', left: theme.spacing(1) }}>
            <IconButton onClick={prev}>
              <ArrowLeft fontSize="large" />
            </IconButton>
          </Box>
        )}
        <div
          onTouchStart={(e) => touchStart(e.touches[0])}
          onTouchMove={(e) => touchMove(e.touches[0])}
          onTouchEnd={touchEnd}
          onMouseDown={touchStart}
          onMouseUp={touchEnd}
          onMouseMove={touchMove}
          style={{
            flexDirection: 'row',
            display: 'flex',
            overflow: 'hidden',
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            paddingBottom: theme.spacing(3),
          }}
          ref={sliceContainerRef}
        >
          {props.children.map((child: any, index: number) => (
            <Box
              key={index}
              flexBasis={itemWidth}
              flexGrow={0}
              flexShrink={0}
              paddingX={2}
              boxSizing={'border-box'}
              display="flex"
              style={{ userSelect: 'none' }}
            >
              {child}
            </Box>
          ))}
        </div>
        {showNaviButtons && (
          <Box style={{ position: 'absolute', right: theme.spacing(1) }}>
            <IconButton onClick={next}>
              <ArrowRight fontSize="large" />
            </IconButton>
          </Box>
        )}
      </div>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} margin={-0.25}>
        {dots}
      </Box>
    </Box>
  );
}

function useSwipeDetection(onSwipeLeft: () => void, onSwipeRight: () => void) {
  const [startPos, setStartPos] = useState<{ x: number; y: number } | null>(null);
  const [endPos, setEndPos] = useState<{ x: number; y: number } | null>(null);
  const touchStart = (evt: { clientX: number; clientY: number }) => {
    const x = evt.clientX;
    const y = evt.clientY;
    setStartPos({ x, y });
  };

  const touchMove = (evt: { clientX: number; clientY: number }) => {
    const x = evt.clientX;
    const y = evt.clientY;
    setEndPos({ x, y });
  };
  const touchEnd = () => {
    if ((startPos?.x ?? 0 + 100) < (endPos?.x ?? 0)) onSwipeRight();
    else if ((endPos?.x ?? 0 + 100) < (startPos?.x ?? 0)) onSwipeLeft();
  };
  return { touchStart, touchMove, touchEnd };
}

function useInterval(intervalTime: number, callback: () => void, enable: boolean = true) {
  const [autoplayInterval, setAutoplayInterval] = useState<NodeJS.Timer>();
  useEffect(() => {
    if (autoplayInterval) clearInterval(autoplayInterval);
    if (enable) {
      setAutoplayInterval(setInterval(callback, intervalTime));
    }
    return () => {
      if (autoplayInterval) clearInterval(autoplayInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable, intervalTime, callback, setAutoplayInterval]);
}
