import { FC } from 'react';
import { Grid, Typography, useTheme, createStyles, makeStyles, Theme, Box, useMediaQuery } from '@material-ui/core';

import sections from '../../../shared/ultilities/sections';
import infomationPana from '../../../assets/explanation/infomationPana.svg';
import easyToUse from '../../../assets/explanation/easyToUse.svg';
import storeAndSync from '../../../assets/explanation/storeAndSync.svg';
import shareAndView from '../../../assets/explanation/shareAndView.svg';
import scalableAndFlexible from '../../../assets/explanation/scalableAndFlexible.svg';

const Explanation: FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box id={sections.explanation} className={styles.container} marginTop={isMobile ? 5 : 15}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={6}>
          <Typography className={styles.title}>The Software for All</Typography>
          <Typography style={{ fontSize: 18, fontWeight: 400, color: '#716C80', fontFamily: 'Inter' }}>
            Every business has their{' '}
            <b>
              <i>unique</i>
            </b>{' '}
            operation and with clear communications and processes, they can operate efficiently. Using DayOne,
            businesses can{' '}
            <b>
              <i>leverage</i>
            </b>{' '}
            on a{' '}
            <b>
              <i>centralization pool of infomation</i>
            </b>{' '}
            and{' '}
            <b>
              <i>analytics</i>
            </b>
            , achieving <b>Operational Excellence</b>.
          </Typography>
          {/* <Box className={styles.item}>
            <Typography className={styles.itemLabel}>Why DayOne?</Typography>
          </Box> */}
          <Box className={styles.item}>
            <img alt="Easy to Use" src={easyToUse} className={styles.image} />
            <Box>
              <Typography className={styles.itemTitle}>Easy to Use</Typography>
              <Typography className={styles.itemText}>User friendly interface that is easy to navigate. </Typography>
            </Box>
          </Box>
          <Box className={styles.item}>
            <img alt="Store & Sync" src={storeAndSync} className={styles.image} />
            <Box>
              <Typography className={styles.itemTitle}>Store & Sync</Typography>
              <Typography className={styles.itemText}>
                Upload, Update and Store information with DayOne. With our App, browsing through relevant contents never
                been easier!{' '}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.item}>
            <img alt="Share & View" src={shareAndView} className={styles.image} />
            <Box>
              <Typography className={styles.itemTitle}>Share & View</Typography>
              <Typography className={styles.itemText}>
                Share files quickly - whether it's to Ben or John. And provide them the access rights to your folders to
                view on-the-go!
              </Typography>
            </Box>
          </Box>
          <Box className={styles.item}>
            <img alt="Scalable and Flexible" src={scalableAndFlexible} className={styles.image} />
            <Box>
              <Typography className={styles.itemTitle}>Scalable and Flexible </Typography>
              <Typography className={styles.itemText}>Solution for any industry, company and size.</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={6}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}
        >
          <img alt="The Software for All" src={infomationPana} style={{ maxWidth: '100%' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Explanation;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { display: 'flex', alignItems: 'center' },
    title: {
      fontFamily: 'Inter',
      fontSize: 36,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0,
      marginBottom: theme.spacing(1.5),
    },
    content: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'italic',
      fontWeight: 700,
      letterSpacing: 0,
      marginBottom: theme.spacing(5),
    },
    item: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    itemLabel: {
      fontFamily: 'Inter',
      fontSize: 22,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0,
      // marginBottom: theme.spacing(1),
    },
    itemTitle: {
      fontFamily: 'Inter',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0,
      marginBottom: theme.spacing(1),
    },
    itemText: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0,
      color: '#716C80',
    },
    image: {
      marginRight: theme.spacing(2.5),
    },
  })
);
