import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, useTheme, makeStyles, createStyles, Theme, useMediaQuery } from '@material-ui/core';

import firebaseApp from 'configs/firebaseApp';
import check from '../../../assets/icons/check.svg';
import pricing from '../../../assets/pricing/pricing.svg';
import features from '../../../shared/ultilities/features.json';
import { Button } from '../../../shared/components/button';
import sections from '../../../shared/ultilities/sections';
import AnalyticsEvents from 'shared/ultilities/events';
import analytics from 'shared/ultilities/analytics';
import env from 'shared/ultilities/environment';

const PricingTable: FC = () => {
  const theme = useTheme();
  const styles = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [freePackage, setFreePackage] = useState<any>(null);

  useEffect(() => {
    if (firebaseApp) {
      firebaseApp
        .firestore()
        .collection('packages')
        .doc('freePackage')
        .get()
        .then((document) => setFreePackage(document.data()));
    }
  }, [firebaseApp]);

  const navigateToRegistration = useCallback(() => {
    analytics.logEvent(AnalyticsEvents.registration_start, { from: 'Pricing' });
    navigate('/registration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const plans = [
    {
      name: 'Free',
      price: '$0',
      renderPrice: () => (
        <Typography align="center" className={styles.planPrice}>
          $0
        </Typography>
      ),
      features: [
        'Full Access to all Features',
        'Lifetime free Support',
        '24/7 Support',
        `${freePackage?.userMaxStaff || 5} Users`,
        `${freePackage?.teamMaxTeam || 2} Teams`,
        'Unlimited Tasks',
        'Unlimited FAQs',
      ],
      url: env.WEBAPP_URL,
    },
    {
      name: 'Pay as you Scale',
      price: '$25 + $12 per outlet/month',
      renderPrice: () => (
        <Typography align="center">
          <span className={styles.planPrice}>$25</span> <br /> + $12 per outlet/month
        </Typography>
      ),
      features: [
        'Full Access to all Features',
        'Lifetime free Support',
        '24/7 Support',
        'Unlimited Users',
        'Unlimited Teams',
        'Unlimited Tasks',
        'Unlimited FAQs',
        'Unlimited Capabilities & Wonders',
        'Pay Once and use as you need',
      ],
      startText: 'Get a Quote',
      url: 'mailto:support@weareday.one',
    },
    {
      name: 'Enterprise',
      price: '',
      features: [
        'Full Access to all Features',
        'Lifetime free Support',
        '24/7 Support',
        'Unlimited Users',
        'Unlimited Teams',
        'Unlimited Tasks',
        'Unlimited FAQs',
        'Unlimited Capabilities & Wonders',
        'Pay Once and use as you need',
      ],
      startText: 'Get a Quote',
      url: 'mailto:support@weareday.one',
    },
  ];

  return (
    <Box id={sections.pricing} display="flex" flexDirection="column" alignItems="center" marginTop={isMobile ? 5 : 7}>
      <Box marginBottom={8}>
        <Typography align="center" style={{ fontWeight: 700 }} className={styles.text2}>
          The Ultimate Deal
        </Typography>
        <Typography align="center" className={styles.text2}>
          No hidden charges applied
        </Typography>
      </Box>
      <Grid container spacing={2} className={styles.gridContainer}>
        {/* <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Box>
            <img src={pricing} alt="pricing" style={{ width: '100%' }} />
          </Box>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Grid container className={styles.gridWrapper}>
            <Grid item>
              <Box paddingLeft={8}>
                {features.map((feature) => (
                  <Box key={feature} display="flex" alignItems="center" className={styles.featureText}>
                    <img src={check} alt="check" className={styles.image} />
                    <Typography className={styles.featureItemText}>{feature}</Typography>
                  </Box>
                ))}
                <Box display="flex" alignItems="flex-end" paddingTop={1.5}>
                  <Box className={styles.priceText} style={{ fontWeight: 700 }}>
                    Only $12
                  </Box>
                  <Box className={styles.priceText2} paddingBottom="10px">
                    per outlet/month
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="stretch" paddingY={2}>
                  <Button
                    text="Get Started"
                    // disabled
                    onClick={navigateToRegistration}
                    // backgroundColor={'gray'}
                    // color={`${colors.white} !important`}
                    paddingVertical={8}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
        {plans.map((plan) => (
          <Grid key={plan.name} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box
              className={styles.planItem}
              bgcolor="white"
              borderRadius={8}
              border="1px solid grey"
              p={2}
              display="flex"
              flexDirection="column">
              <Typography align="center" className={styles.planName}>
                {plan.name}
              </Typography>
              {plan.renderPrice && plan.renderPrice()}
              <Box sx={{ alignSelf: 'center' }}>
                {plan.features.map((feature) => (
                  <Box key={feature} display="flex" alignItems="center" className={styles.featureText}>
                    <img src={check} alt="check" className={styles.image} />
                    <Typography className={styles.featureItemText}>{feature}</Typography>
                  </Box>
                ))}
              </Box>
              <Button
                text={plan.startText || 'Get Started'}
                // disabled
                onClick={() => window.open(plan.url)}
                // backgroundColor={'gray'}
                // color={`${colors.white} !important`}
                paddingVertical={8}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text2: {
      fontWeight: 400,
      fontSize: '2.25rem',
    },
    gridContainer: {
      marginTop: theme.spacing(3.5),
    },
    gridWrapper: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
      },
    },
    bestDealText: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
    pricingTitle: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    pricingSubTitle: {
      fontWeight: 400,
      fontSize: '0.9rem',
      fontStyle: 'italic',
    },
    featureText: {
      marginBottom: theme.spacing(0.5),
    },
    featureItemText: {
      // fontSize: '0.8rem',
      // [theme.breakpoints.down('md')]: {
      //   fontSize: '1rem',
      // },
    },
    image: {
      marginRight: theme.spacing(2),
    },
    priceText: {
      fontWeight: 400,
      fontSize: '3rem',
      marginRight: theme.spacing(0.5),
    },
    priceText2: {
      fontWeight: 400,
      fontSize: '0.85rem',
    },
    planItem: {
      gap: '32px',
    },
    planName: {
      fontWeight: 700,
      fontSize: '28px',
    },
    planPrice: {
      // fontWeight: 600,
      // marginTop: '32px',
      fontSize: '40px',
      fontWeight: 600,
    },
  })
);
