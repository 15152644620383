import { FC, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Collapse, useTheme, useMediaQuery, createStyles, makeStyles, Theme } from '@material-ui/core';

import { Button } from '../button';
import logo from '../../../assets/icons/logo.svg';
import menu from '../../../assets/icons/menu.png';
import close from '../../../assets/icons/close.png';
import colors from '../../ultilities/colors';
import sections from '../../ultilities/sections';
import { AppContext } from '../../../contexts/app.context';
import AnalyticsEvents from 'shared/ultilities/events';
import analytics from 'shared/ultilities/analytics';

const Header: FC = () => {
  const navigate = useNavigate();
  const { onLogin } = useContext(AppContext);

  const styles = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const goToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const navigateToSection = useCallback(
    (id: string) => {
      if (id) {
        navigate('/', { state: { id: id } });
      }
    },
    [navigate]
  );

  const navs = [
    {
      text: 'Why DayOne?',
      onClick: () => {
        analytics.logEvent(AnalyticsEvents.home_view_explanation);
        navigateToSection(sections.explanation);
      },
    },
    {
      text: 'Features',
      onClick: () => {
        analytics.logEvent(AnalyticsEvents.home_view_features);
        navigateToSection(sections.keyFeatures);
      },
    },
    {
      text: 'Pricing',
      onClick: () => {
        analytics.logEvent(AnalyticsEvents.pricing_view, { from: 'Navigation Header' });
        navigate('/pricing');
      },
    },
  ];

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={isSmall ? 'space-between' : 'flex-start'}
        height={110}
        paddingX={theme.spacing(0.5)}
        id={sections.topNav}>
        <img src={logo} alt="logo" className={styles.logo} onClick={goToHome} />
        {!isSmall && (
          <Box display="flex" alignItems="center" flexGrow={1} marginLeft={theme.spacing(1)}>
            {navs.map((nav) => (
              <span key={nav.text} className={styles.navText} onClick={nav.onClick}>
                {nav.text}
              </span>
            ))}
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Button
            text="Login"
            onClick={onLogin}
            paddingVertical={isXs ? theme.spacing(0.5) : theme.spacing(1.5)}
            paddingHorizontal={isXs ? theme.spacing(3) : theme.spacing(8)}
          />
          {isSmall && (
            <Box marginLeft={isXs ? theme.spacing(0.25) : theme.spacing(0.5)}>
              <img src={isOpen ? close : menu} alt="menu" className={styles.menuIcon} onClick={toggleDrawer} />
            </Box>
          )}
        </Box>
      </Box>
      {isSmall && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={styles.smallNav}
            paddingBottom={theme.spacing(0.5)}>
            {navs.map((nav) => (
              <Box key={nav.text} paddingY={theme.spacing(0.25)}>
                <span className={styles.navText} onClick={nav.onClick}>
                  {nav.text}
                </span>
              </Box>
            ))}
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navText: {
      fontWeight: 500,
      fontVariationSettings: `'wght' 500`,
      fontSize: '1.1rem',
      cursor: 'pointer',
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      color: colors.gray,
    },
    logo: {
      cursor: 'pointer',
    },
    menuIcon: {
      cursor: 'pointer',
    },
    smallNav: {
      borderBottom: `2px solid ${colors.lightGray}`,
    },
  })
);
