import { FC } from 'react';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';

import colors from '../../ultilities/colors';

interface Props {
  paddingHorizontal?: number;
  paddingVertical?: number;
  color?: string;
  backgroundColor?: string;
  fontSize?: number | string;
  marginRight?: number;
  marginLeft?: number;
  text: string;
  onClick: Function;
  disabled?: boolean;
}

const Btn: FC<Props> = (props) => {
  const styles = useStyles(props);

  const { onClick, text, disabled } = props;
  return (
    <Button className={styles.button} onClick={() => onClick()} disabled={!!disabled}>
      {text}
    </Button>
  );
};

export default Btn;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 30,
      marginRight: (props: Props) => props.marginRight || 0,
      marginLeft: (props: Props) => props.marginLeft || 0,
      paddingLeft: (props: Props) => props.paddingHorizontal || theme.spacing(2),
      paddingRight: (props: Props) => props.paddingHorizontal || theme.spacing(2),
      paddingTop: (props: Props) => props.paddingVertical || theme.spacing(1),
      paddingBottom: (props: Props) => props.paddingVertical || theme.spacing(1),
      backgroundColor: (props: Props) => props.backgroundColor || colors.black,
      color: (props: Props) => props.color || colors.white,
      fontWeight: 600,
      fontSize: '1rem',
      textTransform: 'none',
      transition: 'ease all .5s',
      '&:hover': {
        backgroundColor: (props: Props) => props.backgroundColor || colors.black,
        opacity: 0.85,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
  })
);
