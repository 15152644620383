"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appCheck = void 0;
var app_check_1 = require("firebase/app-check");
function appCheck(firebaseApp, captchaPublicKey, enableAppCheckDebug, appCheckdebugToken) {
    if (firebaseApp === null || !firebaseApp || captchaPublicKey === '') {
        return null;
    }
    if (enableAppCheckDebug) {
        console.log('App check debug enabled', { enableAppCheckDebug: enableAppCheckDebug, appCheckdebugToken: appCheckdebugToken, captchaPublicKey: captchaPublicKey });
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckdebugToken;
    }
    console.log('App check debug disabled', { enableAppCheckDebug: enableAppCheckDebug, appCheckdebugToken: appCheckdebugToken, captchaPublicKey: captchaPublicKey });
    var appCheck = (0, app_check_1.initializeAppCheck)(firebaseApp, {
        provider: new app_check_1.ReCaptchaV3Provider(captchaPublicKey),
        isTokenAutoRefreshEnabled: true,
    });
    return appCheck;
}
exports.appCheck = appCheck;
