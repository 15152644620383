const AnalyticsEvents = {
  login: 'login',
  pricing_view: 'pricing_view',
  home_view_demo: 'home_view_demo',
  home_view_explanation: 'home_view_explanation',
  home_view_features: 'home_view_features',
  // home_play_video: 'home_play_video', // TODO: Rewrite YoutubeEmbed's implementation
  registration_start: 'registration_start',
  registration_enter_personal_info: 'registration_enter_personal_info',
  registration_enter_business_info: 'registration_enter_business_info',
  registration_subscribe: 'registration_subscribe',
};

export default AnalyticsEvents;
