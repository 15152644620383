import { createContext, useState, useCallback, FC, ReactNode } from 'react';

import analytics from 'shared/ultilities/analytics';
import env from 'shared/ultilities/environment';
import AnalyticsEvents from 'shared/ultilities/events';

interface Props {
  children: ReactNode;
}

export interface IRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  countryCode: ICountryCode;
}

export interface IWorkspaceData {
  businessName: string;
  industry: string;
  companySize: number;
}

export interface ICountryCode {
  iso: string;
  label: string;
  code: string;
}

export interface IPaymentData {
  fullName: string;
  creditCardNumber: string;
  creditCardExpireDate: string;
  cvv: string;
  zipCode: string;
  streetName: string;
  office: string;
  city: string;
  state: string;
}

export interface IContextData {
  registration: IRegistrationData | undefined;
  workSpace: IWorkspaceData | undefined;
  payment: IPaymentData | undefined;
}

const initValues: IContextData = {
  registration: undefined,
  workSpace: undefined,
  payment: undefined,
};

const onLogin = () => {
  analytics.logEvent(AnalyticsEvents.login);
  window.location.href = env.WEBAPP_URL;
};

export const AppContext = createContext<{
  data: IContextData;
  setRegistration: Function;
  setPayment: Function;
  setWorkSpace: Function;
  onLogin: Function;
}>({
  data: initValues,
  setRegistration: () => {},
  setPayment: () => {},
  setWorkSpace: () => {},
  onLogin,
});

export const AppContextProvider: FC<Props> = ({ children }) => {
  const [data, setData] = useState<IContextData>(initValues);

  const setRegistration = useCallback(
    (registration: IRegistrationData) => {
      setData({
        ...data,
        registration,
      });
    },
    [data]
  );

  const setWorkSpace = useCallback(
    (workSpace: IWorkspaceData) => {
      setData({
        ...data,
        workSpace,
      });
    },
    [data]
  );

  const setPayment = useCallback(
    (payment: IPaymentData) => {
      setData({
        ...data,
        payment,
      });
    },
    [data]
  );

  return (
    <AppContext.Provider value={{ data, setRegistration, setPayment, setWorkSpace, onLogin }}>
      {children}
    </AppContext.Provider>
  );
};
