import { FC, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Banner from './components/Banner';
import Explanation from './components/Explanation';
import GetStarted from './components/GetStarted';
import KeyFeatures from './components/KeyFeatures';
import RealLifeApplication from './components/RealLifeApplication';
import Reviews from './components/Reviews';
import { Customers } from '../../shared/components/customers';
import { Footer } from '../../shared/components/footer';
import { Welcome } from '../../shared/components/welcome';

import scrollToElement from '../../shared/ultilities/scrollToElement';

const Dashboard: FC = () => {
  const { state } = useLocation();

  const { id }: any = state || {};

  useEffect(() => {
    if (id) {
      scrollToElement(id);
    }
  }, [id]);

  return (
    <Box>
      <Box mx={{ xs: 1, sm: 5, lg: 15 }}>
        <Banner />
        <Customers text="Trusted by your favorite companies" />
        <Explanation />
        <RealLifeApplication />
        <KeyFeatures />
        <Reviews />
        <GetStarted />
        <Welcome />
      </Box>

      <Footer />
    </Box>
  );
};

export default Dashboard;
